<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="d-flex headSearch">
        <div class="form-item">
          <el-input
            placeholder="输入关键词"
            v-model="formData.keyword"
            clearable
          ></el-input>
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="exportHandle"
            type="warning"
            icon="el-icon-upload2"
            :disabled="!tableData.length || disabledExport"
            >导出</el-button
          >
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @on-handle-zone="onHandleZone"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 关联关键词弹窗 start -->
    <config-dialog ref="zoneConfig" @submit-form="submitForm"></config-dialog>
    <!-- 关联关键词弹窗 end -->
  </section>
</template>
<script>
import ConfigDialog from "./modules/addConfig/index.vue";
import { postMemberSearchTotalList } from "@/api/general/keyword.js";
import { postMemberSearchTotalExport } from "@/api/export/center.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "KeywordSearch",
  components: { ConfigDialog, TableList },
  data() {
    return {
      disabledExport: false,
      total: 0,
      loadingData: false,
      formData: {
        keyword: "",
        page: 1,
        pageSize: 50,
      },
      pay_at: "",
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    /**
     * 导出
     */
    async exportHandle() {
      this.disabledExport = true;
      try {
        let params = this.tool.DeepCopy({}, this.formData);
        delete params.page;
        delete params.pageSize;
        await postMemberSearchTotalExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxMemberSearchList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxMemberSearchList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxMemberSearchList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxMemberSearchList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.pay_at = "";
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxMemberSearchList();
    },
    /**
     * 关联关键词
     */
    onHandleZone(func, val) {
      this.$refs.zoneConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.postAjaxMemberSearchList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxMemberSearchList() {
      this.loadingData = true;
      try {
        const { data } = await postMemberSearchTotalList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postMemberSearchList err", err);
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
</style>
