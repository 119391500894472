var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          type: "index",
          width: "100px",
          align: "center",
          label: "排名",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "keyword",
          align: "center",
          label: "关键词",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "related_word_txt",
          align: "center",
          label: "系统关联关键词",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "related_word_txt",
          align: "center",
          label: "绑定关键词",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("div", [_vm._v(_vm._s(_vm.word(scope.row)))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "k_num",
          align: "center",
          label: "搜索次数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "操作",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEdit(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 关联关键词 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }