<template>
  <div>
    <!--  关联关键词弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="35%"
      class="dialog"
      center
    >
      <div class="contents1">
        <div class="mb-15">
          系统关联关键词： {{ detail.related_word_txt || "" }}
        </div>
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="关联关键词" prop="related_words">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="d-flex a-center mb-10"
            >
              <el-input
                v-model="item.value"
                placeholder="请输入"
                clearable
              ></el-input>
              <div style="margin: 0px 10px">
                <i
                  class="el-icon-plus"
                  style="color: #13ae67"
                  @click="addOption"
                ></i>
              </div>
              <div style="margin: 0px 10px; width: 30px">
                <i
                  class="el-icon-delete"
                  style="color: #13ae67"
                  @click="deleteOption(item)"
                  v-if="index != 0"
                ></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { postMemberSearchBind } from "@/api/general/keyword.js";

export default {
  props: ["businessList", "partitionList", "jumpTypeList"],
  name: "addConfigDialog",
  data() {
    return {
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "关联关键词", //弹框title
      platFormClone: {}, // 复制手续费配置数据，用于初始化
      list: [{ value: "" }],
      platForm: {
        related_words: "",
      },
      detail: {}, // 编辑详情
      rules: {},
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     * 增加关联关键词
     */
    addOption() {
      if (this.list.length < 5) {
        this.list.push({
          value: "",
        });
      }
    },
    /**
     * 删除关联关键词
     */
    deleteOption(item) {
      var index = this.list.indexOf(item);
      if (index !== -1) {
        this.list.splice(index, 1);
      }
    },
    /**
     *初始化数据
     */
    initData(row) {
      this.platForm = {
        ...this.platFormClone,
      };
      this.detail = row;
      this.list = [{ value: "" }];
      // 如果设置关联关键词 回显
      if (
        row.member_search_bind &&
        row.member_search_bind.related_words.length > 0
      ) {
        let tmpArr = [];
        row.member_search_bind.related_words.map((rItem, rIndex) => {
          console.log(rItem, rIndex, "222");
          tmpArr.push({ value: rItem });
        });
        this.list = tmpArr;
        console.log(tmpArr, this.list, "---");
      }
      this.dialogFormVisible = true;
    },
    /**
     * 编辑提交表单
     */
    submitFrom: debounce(function () {
      // 判断下拉选择用户是否都填写
      let tmpArr = [];
      let valueArr = [];
      this.list.map((item) => {
        if (item.value == "") {
          tmpArr.push(item);
        } else {
          valueArr.push(item.value);
        }
      });
      console.log(tmpArr.length, tmpArr, valueArr, "===");
      // 如果只有一个 且为空 可以提交
      if (tmpArr.length > 0 && this.list.length != 1) {
        return this.tool.message("请输入关键词", "error");
      }
      this.$refs.platForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let tmpParam = {
          keyword: this.detail.keyword,
          related_words: valueArr,
        };
        try {
          await postMemberSearchBind(tmpParam);
          this.tool.message("关联成功", "success");
          this.$emit("submit-form"); // 刷新列表
        } catch (error) {
          console.error("postForceIntervene", error);
        } finally {
          this.dialogFormVisible = false;
        }
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;
}
</style>
