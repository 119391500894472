var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "35%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c("div", { staticClass: "mb-15" }, [
                _vm._v(
                  " 系统关联关键词： " +
                    _vm._s(_vm.detail.related_word_txt || "") +
                    " "
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "platForm",
                  attrs: {
                    model: _vm.platForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联关键词", prop: "related_words" } },
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "d-flex a-center mb-10" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                          _c("div", { staticStyle: { margin: "0px 10px" } }, [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              staticStyle: { color: "#13ae67" },
                              on: { click: _vm.addOption },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "0px 10px",
                                width: "30px",
                              },
                            },
                            [
                              index != 0
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: { color: "#13ae67" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOption(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitFrom } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }